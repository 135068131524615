import { users } from '@/store/entity/response';
import State, { Friends, Clubs } from './interface';
import { Types } from './actions';

export default {
  [Types.FETCH_USER_GET_FRIEND_LIST_FETCHING](state: State) {
    state.getUserFriendList.requesting = true;
  },
  [Types.FETCH_USER_GET_FRIEND_LIST_SUCCESS](state: State, payload: Friends) {
    const { friends } = payload;
    state.userFriendList = new users.UserFriends(friends).serialize();
    state.getUserFriendList.requesting = false;
  },
  [Types.FETCH_USER_GET_FRIEND_LIST_FAILED](state: State) {
    state.userFriendList = new users.UserFriends().serialize();
    state.getUserFriendList.requesting = false;
  },
  [Types.FETCH_USER_GET_CLUBS_FETCHING](state: State) {
    state.getUserManagedClubList.requesting = true;
  },
  [Types.FETCH_USER_GET_CLUBS_SUCCESS](state: State, payload: Clubs) {
    const { clubs } = payload;
    state.userManagedClubList = new users.UserManagedClubList(clubs).serialize();
    state.getUserManagedClubList.requesting = false;
  },
  [Types.FETCH_USER_GET_CLUBS_FAILED](state: State) {
    state.userManagedClubList = new users.UserManagedClubList().serialize();
    state.getUserManagedClubList.requesting = false;
  },
};
