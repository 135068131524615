class ClubId {
  private clubId;

  private status;

  constructor(clubId: string, status: string) {
    this.clubId = clubId;
    this.status = status;
  }

  serialize() {
    return {
      clubId: this.clubId,
      status: this.status,
    };
  }
}

export default ClubId;
