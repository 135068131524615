// import RouteMeta from './RouteMeta';

export default {
  path: '/login',
  name: 'login',
  component: () => import(/* webpackChunkName: "login" */ '@/views/LoginPage.vue'),
  params: {},
  props: true,
  meta: {
    auth: false,
  },
  // meta: new RouteMeta({
  //   auth: false,
  // }),
};
