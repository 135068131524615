import { files } from '@/store/entity/response';
import State from './interface';
import { Types } from './actions';

export default {
  [Types.FETCH_FILES_UPLOAD_FILES_FETCHING](state: State) {
    state.uploadFile.requesting = true;
  },
  [Types.FETCH_FILES_UPLOAD_FILES_SUCCESS](state: State, payload: {
    uri: string
  }) {
    const { uri } = payload;
    state.fileUrl = new files.FileUrl(uri).serialize();
    state.uploadFile.requesting = false;
  },
  [Types.FETCH_FILES_UPLOAD_FILES_FAILED](state: State) {
    state.fileUrl = new files.FileUrl().serialize();
    state.uploadFile.requesting = false;
  },
  [Types.FETCH_FILES_DELETE_FILES_FETCHING](state: State) {
    state.deleteFile.requesting = true;
  },
  [Types.FETCH_FILES_DELETE_FILES_SUCCESS](state: State) {
    state.deleteFile.requesting = false;
  },
  [Types.FETCH_FILES_DELETE_FILES_FAILED](state: State) {
    state.deleteFile.requesting = false;
  },
};
