class PinPost {
  private clubId;

  private isPinned;

  constructor(clubId: string, isPinned: boolean) {
    this.clubId = clubId;
    this.isPinned = isPinned;
  }

  serialize() {
    return {
      clubId: this.clubId,
      isPinned: this.isPinned,
    };
  }
}

export default PinPost;
