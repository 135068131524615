import { users } from '@/store/entity/response';
import State from './interface';

const state: State = {
  userFriendList: new users.UserFriends().serialize(),
  userManagedClubList: new users.UserManagedClubList().serialize(),
  getUserFriendList: {
    requesting: false,
  },
  getUserManagedClubList: {
    requesting: false,
  },
};

export default () => state;
