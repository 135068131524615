import jwt from 'jsonwebtoken';
import { getCookie } from '@/utils/storageHelper';

export const getTokenFromLocalStorage = () => getCookie('token');

export const setTokenToLocalStorage = (token: string) => localStorage.setItem('token', token);

export const checkAuth = (token: string) => {
  const decoded = <jwt.CustomJwtPayload>jwt.decode(token);
  if (decoded === null) {
    return false;
  }
  if (decoded.exp * 1000 < Date.now()) {
    return false;
  }
  return true;
};
