import { DraftDetailData } from '@/store/modules/api/posts/interface';

class DraftDetail {
  private draftDetail;

  constructor(
    draftDetail: DraftDetailData = {
      id: '',
      content: '',
      scheduleTime: 0,
      mediaList: [
        {
          uri: '',
          type: '',
          width: 0,
          height: 0,
          thumbnailUri: '',
        },
      ],
      createdTime: 0,
      modifiedTime: 0,
      authorName: '',
      authorAvatar: '',
      userMentionList: [
        {
          aliasId: '',
          name: '',
          avatar: '',
        },
      ],
    },
  ) {
    this.draftDetail = draftDetail;
  }

  serialize() {
    return this.draftDetail;
  }
}

export default DraftDetail;
