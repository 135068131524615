import {
  AuthorIdentities,
  Statuses,
  SearchScope,
} from '@/store/modules/api/posts/interface';

class GetPosts {
  private clubId;

  private authorIdentities;

  private statuses;

  private endTime;

  private startTime;

  private searchScope;

  private searchKeyWord;

  private size;

  private page;

  constructor(
    clubId: string,
    authorIdentities?: AuthorIdentities[],
    statuses?: Statuses[],
    endTime?: number,
    startTime?: number,
    searchScope?: SearchScope,
    searchKeyWord?: string,
    size?: number,
    page?: number,
  ) {
    this.clubId = clubId;
    this.authorIdentities = authorIdentities;
    this.statuses = statuses;
    this.endTime = endTime;
    this.startTime = startTime;
    this.searchScope = searchScope;
    this.searchKeyWord = searchKeyWord;
    this.size = size;
    this.page = page;
  }

  serialize() {
    return {
      clubId: this.clubId,
      authorIdentities: this.authorIdentities,
      statuses: this.statuses,
      endTime: this.endTime,
      startTime: this.startTime,
      searchScope: this.searchScope,
      searchKeyWord: this.searchKeyWord,
      size: this.size,
      page: this.page,
    };
  }
}

export default GetPosts;
