import { Media } from '@/store/modules/api/posts/interface';

class AddDraft {
  private clubId;

  private scheduleTime;

  private content;

  private mediaList;

  constructor(clubId: string, scheduleTime: number, content: string, mediaList: Media[]) {
    this.clubId = clubId;
    this.scheduleTime = scheduleTime;
    this.content = content;
    this.mediaList = mediaList;
  }

  serialize() {
    return {
      clubId: this.clubId,
      scheduleTime: this.scheduleTime,
      content: this.content,
      mediaList: this.mediaList,
    };
  }
}

export default AddDraft;
