export default {
  path: '/club/post/:clubId',
  name: 'clubPost',
  component: () => import(/* webpackChunkName: "login" */ '@/views/ClubManage/PostManage.vue'),
  params: {},
  props: true,
  meta: {
    auth: false,
  },
};
