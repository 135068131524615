class UploadFile {
  private clubId;

  private type;

  private files;

  private isNeedThumbnail;

  private thumbnailSizeNum;

  constructor(clubId: string, type: string, files: {
    binary: BinaryType, name: string, type: string
  }[], isNeedThumbnail?: boolean, thumbnailSizeNum?: number) {
    this.clubId = clubId;
    this.type = type;
    this.files = files;
    this.isNeedThumbnail = isNeedThumbnail;
    this.thumbnailSizeNum = thumbnailSizeNum;
  }

  serialize() {
    const formData = new FormData();
    formData.append('clubId', this.clubId);
    formData.append('type', this.type);
    if (this.isNeedThumbnail) {
      formData.append('isNeedThumbnail', this.isNeedThumbnail.toString());
    }
    if (this.thumbnailSizeNum) {
      formData.append('thumbnailSizeNum', this.thumbnailSizeNum.toString());
    }
    this.files.forEach((el) => {
      formData.append('files', new Blob([el.binary], { type: el.type }), el.name);
    });
    return formData;
  }
}

export default UploadFile;
