export default {
  path: '/club',
  name: 'club',
  component: () => import(/* webpackChunkName: "login" */ '@/views/ClubManage/MainPage.vue'),
  params: {},
  props: true,
  meta: {
    auth: true,
  },
};
