class FileUrl {
  private fileUrl;

  constructor(fileUrl = '') {
    this.fileUrl = fileUrl;
  }

  serialize() {
    return this.fileUrl;
  }
}

export default FileUrl;
