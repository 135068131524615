class ScheduleTime {
  private scheduleTime;

  constructor(scheduleTime: number) {
    this.scheduleTime = scheduleTime;
  }

  serialize() {
    return {
      scheduleTime: this.scheduleTime,
    };
  }
}

export default ScheduleTime;
