import GetPosts from './GetPosts';
import PublishAndEditPost from './PublishAndEditPost';
import Posts from './Posts';
import ClubId from './ClubId';
import PinPost from './PinPost';
import AddDraft from './AddDraft';
import Drafts from './Drafts';
import EditDraft from './EditDraft';
import ScheduleTime from './ScheduleTime';

export default {
  GetPosts,
  PublishAndEditPost,
  Posts,
  ClubId,
  PinPost,
  AddDraft,
  Drafts,
  EditDraft,
  ScheduleTime,
};
