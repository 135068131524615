import { posts } from '@/store/entity/response';
import State from './interface';

const state: State = {
  posts: new posts.Posts().serialize(),
  postDetail: new posts.PostDetail().serialize(),
  draftDetail: new posts.DraftDetail().serialize(),
  getPosts: {
    requesting: false,
  },
  publishPost: {
    requesting: false,
  },
  deletePosts: {
    requesting: false,
  },
  getPostDetail: {
    requesting: false,
  },
  editPost: {
    requesting: false,
  },
  pinPost: {
    requesting: false,
  },
  restoreDeletedPost: {
    requesting: false,
  },
  addDraft: {
    requesting: false,
  },
  deleteDrafts: {
    requesting: false,
  },
  getDraftDetail: {
    requesting: false,
  },
  editDraft: {
    requesting: false,
  },
  publishPostByDraft: {
    requesting: false,
  },
  addPublishSchedule: {
    requesting: false,
  },
  cancelPublishSchedule: {
    requesting: false,
  },
};

export default () => state;
