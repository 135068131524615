// import { Commit } from 'vuex';
import {
  setCookie, deleteCookie, setStorage, getStorage, deleteStorage,
} from '@/utils/storageHelper';
import jwtDecoder from '@/utils/jwtDecoder';
import State from './interface';
import { Types } from './actions';

export default {
  [Types.FETCH_SESSION_GET_TOKEN_FETCHING](state: State) {
    state.getToken.requesting = true;
  },
  [Types.FETCH_SESSION_GET_TOKEN_SUCCESS](
    state: State,
    payload: {
      token: string;
    },
  ) {
    const { token } = payload;
    state.token = token;
    state.getToken.requesting = false;
  },
  [Types.FETCH_SESSION_GET_TOKEN_FAILED](state: State) {
    state.token = '';
    state.getToken.requesting = false;
  },
  setOnTimeUserInfo(state: State, token: string) {
    const onTimeUserInfo = jwtDecoder(token);
    setCookie('token', token, {
      expires: new Date(onTimeUserInfo.exp * 1000),
    });
    state.token = token;
    state.onTimeUserInfo = onTimeUserInfo;
  },
  cleanOnTimeUserInfo(state: State) {
    deleteCookie('token', {
      expires: state.onTimeUserInfo.exp * 1000,
    });
    state.onTimeUserInfo = {
      avatar: '',
      token: '',
      openId: '',
      name: '',
      phoneCountryCode: '',
      phone: '',
      iat: 0,
      exp: 0,
    };
  },
  updateAllUserList(state: State) {
    const allUserToken: string[] = getStorage('allUserToken').split(';');
    const allUserInfoList = jwtDecoder(allUserToken);
    const beforeLoginRecord = allUserInfoList.filter(
      (user) => user.openId === state.onTimeUserInfo.openId,
    );
    let updateAllUserToken: string[] = [...allUserToken];
    if (beforeLoginRecord.length === 0) {
      updateAllUserToken = updateAllUserToken.concat([state.token]);
    } else {
      updateAllUserToken.splice(allUserToken.indexOf(beforeLoginRecord[0].token), 1, state.token);
    }

    setStorage('allUserToken', updateAllUserToken.join(';'));
    state.allUserInfoList = jwtDecoder(updateAllUserToken);
  },
};
