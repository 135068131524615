import jwt from 'jsonwebtoken';

type UserInfoWithToken = jwt.CustomJwtPayload & { token: string };

function decode(token: string): UserInfoWithToken;
function decode(token: string[]): UserInfoWithToken[];

function decode(token: string | string[]): UserInfoWithToken | UserInfoWithToken[] {
  if (Array.isArray(token)) {
    const decodeList = token.map((el: string) => ({
      token: el,
      ...(<jwt.CustomJwtPayload>jwt.decode(el)),
    }));
    return decodeList;
  }
  const decodeData = {
    token,
    ...(<jwt.CustomJwtPayload>jwt.decode(token)),
  };
  return decodeData;
}

export default decode;
