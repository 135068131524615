import { files } from '@/store/entity/response';
import State from './interface';

const state: State = {
  fileUrl: new files.FileUrl().serialize(),
  uploadFile: {
    requesting: false,
  },
  deleteFile: {
    requesting: false,
  },
};

export default () => state;
