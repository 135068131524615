import { getCookie, getStorage } from '@/utils/storageHelper';
import jwtDecoder from '@/utils/jwtDecoder';
import State from './interface';

const state: State = {
  token: getCookie('token'),
  onTimeUserInfo: jwtDecoder(getCookie('token')),
  allUserInfoList: jwtDecoder(getStorage('allUserToken').split(';')),
  getToken: {
    requesting: false,
  },
};

export default () => state;
