import sessions from './sessions';
import posts from './posts';
import users from './users';
import files from './files';

export default {
  modules: {
    sessions,
    posts,
    users,
    files,
  },
};
