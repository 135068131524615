import { Post } from '@/store/modules/api/posts/interface';

class Posts {
  private list;

  private totalCount;

  private totalPage;

  private currentPage;

  constructor(
    totalCount = 0,
    totalPage = 0,
    currentPage = 0,
    list: Post[] = [
      {
        id: '',
        authorIdentity: '',
        authorOpenId: '',
        status: '',
        publishTime: 0,
        content: '',
        authorName: '',
        isPinned: false,
        mediaList: [
          {
            uri: '',
            type: '',
            width: 0,
            height: 0,
            thumbnailUri: '',
          },
        ],
        userMentionList: [
          {
            aliasId: '',
            name: '',
            avatar: '',
          },
        ],
      },
    ],
  ) {
    this.totalCount = totalCount;
    this.totalPage = totalPage;
    this.currentPage = currentPage;
    this.list = list;
  }

  serialize() {
    return {
      list: this.list,
      totalCount: this.totalCount,
      totalPage: this.totalPage,
      currentPage: this.currentPage,
    };
  }
}

export default Posts;
