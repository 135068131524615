import { posts } from '@/store/entity/response';
import State, { Post, PostDetailData, DraftDetailData } from './interface';
import { Types } from './actions';

export default {
  [Types.FETCH_POSTS_GET_POSTS_AND_DRAFTS_LIST_FETCHING](state: State) {
    state.getPosts.requesting = true;
  },
  [Types.FETCH_POSTS_GET_POSTS_AND_DRAFTS_LIST_SUCCESS](
    state: State,
    payload: {
      posts: Post[];
      totalCount: number;
      totalPage: number;
      currentPage: number;
    },
  ) {
    const {
      posts: list, totalCount, totalPage, currentPage,
    } = payload;
    state.posts = new posts.Posts(totalCount, totalPage, currentPage, list).serialize();
    state.getPosts.requesting = false;
  },
  [Types.FETCH_POSTS_GET_POSTS_AND_DRAFTS_LIST_FAILED](state: State) {
    state.posts = new posts.Posts().serialize();
    state.getPosts.requesting = false;
  },
  [Types.FETCH_POSTS_PUBLISH_POST_FETCHING](state: State) {
    state.publishPost.requesting = true;
  },
  [Types.FETCH_POSTS_PUBLISH_POST_SUCCESS](state: State) {
    state.publishPost.requesting = false;
  },
  [Types.FETCH_POSTS_PUBLISH_POST_FAILED](state: State) {
    state.publishPost.requesting = false;
  },
  [Types.FETCH_POSTS_DELETE_POSTS_FETCHING](state: State) {
    state.deletePosts.requesting = true;
  },
  [Types.FETCH_POSTS_DELETE_POSTS_SUCCESS](state: State) {
    state.deletePosts.requesting = false;
  },
  [Types.FETCH_POSTS_DELETE_POSTS_FAILED](state: State) {
    state.deletePosts.requesting = false;
  },
  [Types.FETCH_POSTS_GET_POST_DETAIL_FETCHING](state: State) {
    state.getPostDetail.requesting = true;
  },
  [Types.FETCH_POSTS_GET_POST_DETAIL_SUCCESS](state: State, payload: PostDetailData) {
    state.postDetail = new posts.PostDetail(payload).serialize();
    state.getPostDetail.requesting = false;
  },
  [Types.FETCH_POSTS_GET_POST_DETAIL_FAILED](state: State) {
    state.postDetail = new posts.PostDetail().serialize();
    state.getPostDetail.requesting = false;
  },
  [Types.FETCH_POSTS_EDIT_POST_FETCHING](state: State) {
    state.editPost.requesting = true;
  },
  [Types.FETCH_POSTS_EDIT_POST_SUCCESS](state: State) {
    state.editPost.requesting = false;
  },
  [Types.FETCH_POSTS_EDIT_POST_FAILED](state: State) {
    state.editPost.requesting = false;
  },
  [Types.FETCH_POSTS_PIN_POST_FETCHING](state: State) {
    state.pinPost.requesting = true;
  },
  [Types.FETCH_POSTS_PIN_POST_SUCCESS](state: State) {
    state.pinPost.requesting = false;
  },
  [Types.FETCH_POSTS_PIN_POST_FAILED](state: State) {
    state.pinPost.requesting = false;
  },
  [Types.FETCH_POSTS_RESTORE_DELETED_POST_FETCHING](state: State) {
    state.restoreDeletedPost.requesting = true;
  },
  [Types.FETCH_POSTS_RESTORE_DELETED_POST_SUCCESS](state: State) {
    state.restoreDeletedPost.requesting = false;
  },
  [Types.FETCH_POSTS_RESTORE_DELETED_POST_FAILED](state: State) {
    state.restoreDeletedPost.requesting = false;
  },
  [Types.FETCH_POSTS_ADD_DRAFT_FETCHING](state: State) {
    state.addDraft.requesting = true;
  },
  [Types.FETCH_POSTS_ADD_DRAFT_SUCCESS](state: State) {
    state.addDraft.requesting = false;
  },
  [Types.FETCH_POSTS_ADD_DRAFT_FAILED](state: State) {
    state.addDraft.requesting = false;
  },
  [Types.FETCH_POSTS_DELETE_DRAFTS_FETCHING](state: State) {
    state.deleteDrafts.requesting = true;
  },
  [Types.FETCH_POSTS_DELETE_DRAFTS_SUCCESS](state: State) {
    state.deleteDrafts.requesting = false;
  },
  [Types.FETCH_POSTS_DELETE_DRAFTS_FAILED](state: State) {
    state.deleteDrafts.requesting = false;
  },
  [Types.FETCH_POSTS_GET_DRAFT_DETAIL_FETCHING](state: State) {
    state.getDraftDetail.requesting = true;
  },
  [Types.FETCH_POSTS_GET_DRAFT_DETAIL_SUCCESS](state: State, payload: DraftDetailData) {
    state.draftDetail = new posts.DraftDetail(payload).serialize();
    state.getDraftDetail.requesting = false;
  },
  [Types.FETCH_POSTS_GET_DRAFT_DETAIL_FAILED](state: State) {
    state.getDraftDetail.requesting = false;
  },
  [Types.FETCH_POSTS_EDIT_DRAFT_FETCHING](state: State) {
    state.editDraft.requesting = true;
  },
  [Types.FETCH_POSTS_EDIT_DRAFT_SUCCESS](state: State) {
    state.editDraft.requesting = false;
  },
  [Types.FETCH_POSTS_EDIT_DRAFT_FAILED](state: State) {
    state.editDraft.requesting = false;
  },
  [Types.FETCH_POSTS_PUBLISH_POST_BY_DRAFT_FETCHING](state: State) {
    state.publishPostByDraft.requesting = true;
  },
  [Types.FETCH_POSTS_PUBLISH_POST_BY_DRAFT_SUCCESS](state: State) {
    state.publishPostByDraft.requesting = false;
  },
  [Types.FETCH_POSTS_PUBLISH_POST_BY_DRAFT_FAILED](state: State) {
    state.publishPostByDraft.requesting = false;
  },
  [Types.FETCH_POSTS_ADD_PUBLISH_SCHEDULE_FETCHING](state: State) {
    state.addPublishSchedule.requesting = true;
  },
  [Types.FETCH_POSTS_ADD_PUBLISH_SCHEDULE_SUCCESS](state: State) {
    state.addPublishSchedule.requesting = false;
  },
  [Types.FETCH_POSTS_ADD_PUBLISH_SCHEDULE_FAILED](state: State) {
    state.addPublishSchedule.requesting = false;
  },
  [Types.FETCH_POSTS_CANCEL_PUBLISH_SCHEDULE_FETCHING](state: State) {
    state.cancelPublishSchedule.requesting = true;
  },
  [Types.FETCH_POSTS_CANCEL_PUBLISH_SCHEDULE_SUCCESS](state: State) {
    state.cancelPublishSchedule.requesting = false;
  },
  [Types.FETCH_POSTS_CANCEL_PUBLISH_SCHEDULE_FAILED](state: State) {
    state.cancelPublishSchedule.requesting = false;
  },
  emptyPostsAndDraftList(state: State) {
    state.posts = new posts.Posts().serialize();
  },
};
