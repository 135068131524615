import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AuthMiddleware from './authMiddleware';
import Login from './login';
import Club from './club';
import ClubPost from './clubPost';

const routes: Array<RouteRecordRaw> = [
  Login,
  Club,
  ClubPost,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(AuthMiddleware);

export default router;
