import qs from 'qs';
import { Dispatch } from 'vuex';
import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';
import { files as filesRequest } from '@/store/entity/request';

const namespace = '/files';

export const Types = {
  ...createApiType([
    'FETCH_FILES_UPLOAD_FILES',
    'FETCH_FILES_DELETE_FILES',
  ]),
};

export default {
  [Types.FETCH_FILES_UPLOAD_FILES]({ dispatch }: { dispatch: Dispatch }, {
    clubId,
    type,
    files,
    isNeedThumbnail,
    thumbnailSizeNum,
    onSuccess,
    onFailed,
  }: {
    clubId: string
    type: string
    files: {
      binary: BinaryType,
      name: string,
      type: string,
    }[]
    isNeedThumbnail: boolean
    thumbnailSizeNum: number
    onSuccess?: (args?: object) => void,
    onFailed?: (args?: object) => void,
    }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_FILES_UPLOAD_FILES),
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      endpoint: `${namespace}/batch`,
      data: new filesRequest.UploadFile(
        clubId,
        type,
        files,
        isNeedThumbnail,
        thumbnailSizeNum,
      ).serialize(),
      auth: true,
    }).then((response) => {
      if (onSuccess) onSuccess(response);
    }).catch(((err) => {
      if (onFailed) onFailed(err);
    }));
  },
  [Types.FETCH_FILES_DELETE_FILES]({ dispatch }: { dispatch: Dispatch }, {
    clubId,
    type,
    fileNames,
    onSuccess,
    onFailed,
  }: {
    clubId: string
    type: string
    fileNames: string[]
    onSuccess?: (args?: object) => void,
    onFailed?: (args?: object) => void,
  }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_FILES_DELETE_FILES),
      method: 'DELETE',
      endpoint: `${namespace}/batch?${qs.stringify(
        new filesRequest.DeleteFile(
          clubId,
          type,
          fileNames,
        ).serialize(),
        { indices: false },
      )}`,
      auth: true,
    }).then((response) => {
      if (onSuccess) onSuccess(response);
    }).catch(((err) => {
      if (onFailed) onFailed(err);
    }));
  },
};
