class Code {
  private code;

  constructor({
    code = '',
  }) {
    this.code = code;
  }

  serialize() {
    return {
      code: this.code,
    };
  }
}

export default Code;
