import { Media } from '@/store/modules/api/posts/interface';

class EditDraft {
  private scheduleTime;

  private content;

  private mediaList;

  constructor(scheduleTime: number, content: string, mediaList: Media[]) {
    this.scheduleTime = scheduleTime;
    this.content = content;
    this.mediaList = mediaList;
  }

  serialize() {
    return {
      scheduleTime: this.scheduleTime,
      content: this.content,
      mediaList: this.mediaList,
    };
  }
}

export default EditDraft;
