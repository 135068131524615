import { PostDetailData } from '@/store/modules/api/posts/interface';

class PostDetail {
  private postDetail;

  constructor(
    postDetail: PostDetailData = {
      id: '',
      isPinned: false,
      content: '',
      userMentionList: [
        {
          aliasId: '',
          name: '',
          avatar: '',
        },
      ],
      mediaList: [
        {
          uri: '',
          type: '',
          width: 0,
          height: 0,
          thumbnailUri: '',
        },
      ],
      createdTime: 0,
      modifiedTime: 0,
      authorName: '',
      authorAvatar: '',
      commentCount: 0,
      shareCount: 0,
      reactionCount: 0,
      myReactionType: '',
      reactionTypeList: [''],
    },
  ) {
    this.postDetail = postDetail;
  }

  serialize() {
    return this.postDetail;
  }
}

export default PostDetail;
