import { Dispatch, Commit } from 'vuex';
import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';
import { sessions } from '@/store/entity/request';
import State from './interface';

const namespace = '/sessions';

export const Types = {
  ...createApiType(['FETCH_SESSION_GET_TOKEN']),
};

export default {
  [Types.FETCH_SESSION_GET_TOKEN](
    { dispatch, commit }: { dispatch: Dispatch, commit: Commit },
    {
      code,
      onSuccess,
      onFailed,
    }: {
      code: string;
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_SESSION_GET_TOKEN),
      method: 'POST',
      endpoint: `${namespace}`,
      data: new sessions.Code({
        code,
      }).serialize(),
      auth: false,
    })
      .then((response) => {
        commit('setOnTimeUserInfo', response.data.token);
        commit('updateAllUserList');
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },
  logout(
    { commit }: { commit: Commit },
    {
      onSuccess,
      onFailed,
    }: {
      onSuccess?: (args?: object | string) => void;
      onFailed?: (args?: object | string) => void;
    } = {},
  ) {
    try {
      commit('cleanOnTimeUserInfo');
      if (onSuccess) onSuccess('logout');
    } catch (e) {
      if (onFailed && e instanceof Error) onFailed(e);
      throw e;
    }
  },
  switchOnTimeUser(
    { commit, state }: { commit: Commit, state: State },
    {
      openId,
      onSuccess,
      onFailed,
    }: {
      openId: string;
      onSuccess?: (args?: string) => void;
      onFailed?: (args?: string) => void;
    },
  ) {
    const selectUser = state.allUserInfoList.filter((user) => user.openId === openId)[0];
    if (selectUser.exp * 1000 <= Date.now()) {
      if (onFailed) onFailed('user expired');
      return;
    }
    commit('setOnTimeUserInfo', selectUser.token);
    if (onSuccess) onSuccess('switch user success !');
  },
};
