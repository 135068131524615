import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import App from './App.vue';
import router from './router';
import store, { key } from './store';
import { loadFonts } from './plugins/webfontloader';
import '@/assets/style/global.sass';

loadFonts();

const app = createApp(App);
// eslint-disable-next-line no-restricted-syntax
for (const [idx, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(idx, component);
}
app.use(router)
  .use(store, key)
  .use(ElementPlus)
  .mount('#app');
