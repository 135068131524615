import qs from 'qs';
import { Dispatch, Commit } from 'vuex';
import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';
import { posts } from '@/store/entity/request';
import {
  AuthorIdentities,
  Statuses,
  SearchScope,
  Media,
} from '@/store/modules/api/posts/interface';

const namespace = '/posts';

export const Types = {
  ...createApiType([
    'FETCH_POSTS_GET_POSTS_AND_DRAFTS_LIST', // 取得貼文列表
    'FETCH_POSTS_PUBLISH_POST', // 發布貼文(新增貼文-> 立即發布)
    'FETCH_POSTS_DELETE_POSTS', // 刪除貼文
    'FETCH_POSTS_GET_POST_DETAIL', // 取得貼文細節
    'FETCH_POSTS_EDIT_POST', // 編輯貼文
    'FETCH_POSTS_PIN_POST', // 釘選貼文
    'FETCH_POSTS_RESTORE_DELETED_POST', // 復原貼文
    'FETCH_POSTS_ADD_DRAFT', // 新增草稿
    'FETCH_POSTS_DELETE_DRAFTS', // 單一/批次刪除草稿
    'FETCH_POSTS_GET_DRAFT_DETAIL', // 取得草稿細節
    'FETCH_POSTS_EDIT_DRAFT', // 編輯草稿
    'FETCH_POSTS_PUBLISH_POST_BY_DRAFT', // 單一/批次將草稿發布為貼文
    'FETCH_POSTS_ADD_PUBLISH_SCHEDULE', // 新增排程
    'FETCH_POSTS_CANCEL_PUBLISH_SCHEDULE', // 取消排程
    'EMPTY_POSTS_AND_DRAFT_LIST', // 清空貼文列表的值
  ]),
};

export default {
  async [Types.FETCH_POSTS_GET_POSTS_AND_DRAFTS_LIST](
    { dispatch }: { dispatch: Dispatch },
    {
      clubId,
      authorIdentities,
      statuses,
      endTime,
      startTime,
      searchScope,
      searchKeyWord,
      size,
      page,
      onSuccess,
      onFailed,
    }: {
      clubId: string;
      authorIdentities?: AuthorIdentities[];
      statuses?: Statuses[];
      endTime?: number;
      startTime?: number;
      searchScope?: SearchScope;
      searchKeyWord?: string;
      size?: number;
      page?: number;
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_GET_POSTS_AND_DRAFTS_LIST),
      method: 'GET',
      endpoint: `${namespace}?${qs.stringify(
        new posts.GetPosts(
          clubId,
          authorIdentities,
          statuses,
          endTime,
          startTime,
          searchScope,
          searchKeyWord,
          size,
          page,
        ).serialize(),
        { indices: false },
      )}`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_PUBLISH_POST](
    { dispatch }: { dispatch: Dispatch },
    {
      clubId,
      content,
      mediaList,
      onSuccess,
      onFailed,
    }: {
      clubId: string;
      content: string;
      mediaList: Media[];
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_PUBLISH_POST),
      method: 'POST',
      endpoint: `${namespace}`,
      data: new posts.PublishAndEditPost(clubId, content, mediaList).serialize(),
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_DELETE_POSTS](
    { dispatch }: { dispatch: Dispatch },
    {
      postIds,
      onSuccess,
      onFailed,
    }: {
      postIds: string[];
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_DELETE_POSTS),
      method: 'DELETE',
      endpoint: `${namespace}?${qs.stringify(
        new posts.Posts(postIds).serialize(),
        { indices: false },
      )}`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_GET_POST_DETAIL](
    { dispatch }: { dispatch: Dispatch },
    {
      postId,
      clubId,
      status,
      onSuccess,
      onFailed,
    }: {
      postId: string;
      clubId: string;
      status: string,
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_GET_POST_DETAIL),
      method: 'GET',
      endpoint: `${namespace}/${postId}`,
      params: new posts.ClubId(clubId, status).serialize(),
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_EDIT_POST](
    { dispatch }: { dispatch: Dispatch },
    {
      postId,
      clubId,
      content,
      mediaList,
      onSuccess,
      onFailed,
    }: {
      postId: string;
      clubId: string;
      content: string;
      mediaList: Media[];
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_EDIT_POST),
      method: 'PUT',
      endpoint: `${namespace}/${postId}`,
      data: new posts.PublishAndEditPost(clubId, content, mediaList).serialize(),
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_PIN_POST](
    { dispatch }: { dispatch: Dispatch },
    {
      postId,
      clubId,
      isPinned,
      onSuccess,
      onFailed,
    }: {
      postId: string;
      clubId: string;
      isPinned: boolean;
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_EDIT_POST),
      method: 'PUT',
      endpoint: `${namespace}/${postId}/pin`,
      data: new posts.PinPost(clubId, isPinned).serialize(),
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_RESTORE_DELETED_POST](
    { dispatch }: { dispatch: Dispatch },
    {
      postId,
      onSuccess,
      onFailed,
    }: {
      postId: string;
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_RESTORE_DELETED_POST),
      method: 'POST',
      endpoint: `${namespace}/${postId}/restore`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_ADD_DRAFT](
    { dispatch }: { dispatch: Dispatch },
    {
      clubId,
      scheduleTime,
      content,
      mediaList,
      onSuccess,
      onFailed,
    }: {
      clubId: string;
      scheduleTime: number;
      content: string;
      mediaList: Media[];
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_ADD_DRAFT),
      method: 'POST',
      endpoint: `${namespace}/drafts`,
      data: new posts.AddDraft(clubId, scheduleTime, content, mediaList).serialize(),
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_DELETE_DRAFTS](
    { dispatch }: { dispatch: Dispatch },
    {
      draftIds,
      onSuccess,
      onFailed,
    }: {
      draftIds: string[];
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_DELETE_DRAFTS),
      method: 'DELETE',
      endpoint: `${namespace}/drafts?${qs.stringify(
        new posts.Drafts(draftIds).serialize(),
        { indices: false },
      )}`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_GET_DRAFT_DETAIL](
    { dispatch }: { dispatch: Dispatch },
    {
      draftId,
      onSuccess,
      onFailed,
    }: {
      draftId: string;
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_GET_DRAFT_DETAIL),
      method: 'GET',
      endpoint: `${namespace}/drafts/${draftId}`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_EDIT_DRAFT](
    { dispatch }: { dispatch: Dispatch },
    {
      draftId,
      scheduleTime,
      content,
      mediaList,
      onSuccess,
      onFailed,
    }: {
      draftId: string;
      scheduleTime: number;
      content: string;
      mediaList: Media[];
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_EDIT_DRAFT),
      method: 'PUT',
      endpoint: `${namespace}/drafts/${draftId}`,
      data: new posts.EditDraft(scheduleTime, content, mediaList).serialize(),
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_PUBLISH_POST_BY_DRAFT](
    { dispatch }: { dispatch: Dispatch },
    {
      draftIds,
      onSuccess,
      onFailed,
    }: {
      draftIds: string[];
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_PUBLISH_POST_BY_DRAFT),
      method: 'POST',
      endpoint: `${namespace}/drafts/publish`,
      data: new posts.Drafts(draftIds).serialize(),
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_ADD_PUBLISH_SCHEDULE](
    { dispatch }: { dispatch: Dispatch },
    {
      draftId,
      scheduleTime,
      onSuccess,
      onFailed,
    }: {
      draftId: string;
      scheduleTime: number;
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_ADD_PUBLISH_SCHEDULE),
      method: 'POST',
      endpoint: `${namespace}/drafts/${draftId}/schedule`,
      data: new posts.ScheduleTime(scheduleTime).serialize(),
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  async [Types.FETCH_POSTS_CANCEL_PUBLISH_SCHEDULE](
    { dispatch }: { dispatch: Dispatch },
    {
      draftId,
      onSuccess,
      onFailed,
    }: {
      draftId: string;
      onSuccess?: (args?: object) => void;
      onFailed?: (args?: object) => void;
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_POSTS_CANCEL_PUBLISH_SCHEDULE),
      method: 'DELETE',
      endpoint: `${namespace}/drafts/${draftId}/schedule`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onFailed) onFailed(err);
      });
  },

  emptyPostsAanDraftsList(
    { commit }: { commit: Commit },
    {
      onSuccess,
      onFailed,
    }: {
      onSuccess?: (args?: object | string) => void;
      onFailed?: (args?: object | string) => void;
    } = {},
  ) {
    try {
      commit('emptyPostsAndDraftList');
      if (onSuccess) onSuccess('success');
    } catch (e) {
      if (onFailed && e instanceof Error) onFailed(e);
      throw e;
    }
  },
};
