import { Club } from '@/store/modules/api/users/interface';

class UserManagedClubList {
  private clubs;

  constructor(
    clubs: Club[] = [{
      id: '',
      subject: '',
      photoUri: '',
    }],
  ) {
    this.clubs = clubs;
  }

  serialize() {
    return this.clubs;
  }
}

export default UserManagedClubList;
