import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { checkAuth } from '@/utils/auth';
import store from '../store';
import Login from './login';
import Club from './club';

export default async function authMiddleware(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const { token } = store.state.api.sessions;
  const isTokenValid = checkAuth(token);
  if (!isTokenValid) { // 完全沒有權限
    if (to?.query?.code) { // 沒權限但需要登入
      return next({
        ...Login,
        query: { codeForLogin: to.query.code },
      });
    }
    if (to.name !== Login.name) {
      return next(Login);
    }
    return next();
  }

  if (to?.path === '/') { // 有權限，以另一組帳號登入
    if (to?.query?.code) {
      return next({
        ...Login,
        query: { codeForLogin: to.query.code },
      });
    }
    return next(Club);
  }
  return next();
}
