class DeleteDrafts {
  private draftIds;

  constructor(draftIds: string[]) {
    this.draftIds = draftIds;
  }

  serialize() {
    return {
      draftIds: this.draftIds,
    };
  }
}

export default DeleteDrafts;
