import { Dispatch } from 'vuex';
import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

const namespace = '/users';

export const Types = {
  ...createApiType([
    'FETCH_USER_GET_FRIEND_LIST',
    'FETCH_USER_GET_CLUBS',
  ]),
};

export default {
  [Types.FETCH_USER_GET_FRIEND_LIST]({ dispatch }: { dispatch: Dispatch }, {
    onSuccess,
    onFailed,
  }: {
    onSuccess?: (args?: object) => void,
    onFailed?: (args?: object) => void,
  } = {}) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_USER_GET_FRIEND_LIST),
      method: 'GET',
      endpoint: `${namespace}/friend`,
      auth: true,
    }).then((response) => {
      if (onSuccess) onSuccess(response);
    }).catch(((err) => {
      if (onFailed) onFailed(err);
    }));
  },

  [Types.FETCH_USER_GET_CLUBS]({ dispatch }: { dispatch: Dispatch }, {
    onSuccess,
    onFailed,
  }: {
    onSuccess?: (args?: object) => void,
    onFailed?: (args?: object) => void,
  } = {}) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.FETCH_USER_GET_CLUBS),
      method: 'GET',
      endpoint: `${namespace}/club`,
      auth: true,
    }).then((response) => {
      if (onSuccess) onSuccess(response);
    }).catch(((err) => {
      if (onFailed) onFailed(err);
    }));
  },

};
