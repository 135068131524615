class DeletePosts {
  private postIds;

  constructor(postIds: string[]) {
    this.postIds = postIds;
  }

  serialize() {
    return {
      postIds: this.postIds,
    };
  }
}

export default DeletePosts;
