import { Friend } from '@/store/modules/api/users/interface';

class UserFriends {
  private friends;

  constructor(friends: Friend[] = [{
    aliasId: '',
    name: '',
    avatar: '',
  }]) {
    this.friends = friends;
  }

  serialize() {
    return this.friends;
  }
}

export default UserFriends;
