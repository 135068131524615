class DeleteFile {
  private clubId;

  private type;

  private fileNames;

  constructor(clubId: string, type: string, fileNames: string[]) {
    this.clubId = clubId;
    this.type = type;
    this.fileNames = fileNames;
  }

  serialize() {
    return {
      clubId: this.clubId,
      type: this.type,
      fileNames: this.fileNames,
    };
  }
}

export default DeleteFile;
