import {
  mergeRight, reduce, concat, compose,
} from 'ramda';

const upperCaseList = (stringList: string[]) => stringList.map(
  (string: string) => string.toUpperCase(),
);
const typesBuilder = (keys: string[]) => keys.map((key: string) => ({ [key]: key }));

const apiTypeListBuilder = (key: string) => [
  key,
  `${key}_FETCHING`,
  `${key}_SUCCESS`,
  `${key}_FAILED`,
];
const apiTypesBuilder = (apiNameList: string[]) => apiNameList.map(
  (apiName: string) => apiTypeListBuilder(apiName),
);

const mergeElems = (elems: (object | [])[] = [{}]) => (
  Array.isArray(elems[0]) ? reduce(concat, [], elems) : reduce(mergeRight, {}, elems)
);

export const createType = (typeList:string[] = ['']) => (
  compose(mergeElems, typesBuilder, upperCaseList)(typeList)
);

export const createApiType = (typeList:string[] = ['']) => (
  compose(createType, mergeElems, apiTypesBuilder)(typeList)
);

export const createApiMutationTypes = (apiType: string) => ({
  requesting: `${apiType}_FETCHING`,
  success: `${apiType}_SUCCESS`,
  error: `${apiType}_FAILED`,
});
